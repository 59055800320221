import { Container } from "react-bootstrap";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import PostbackListItem from "./PostbackListItem";
import api from "./api";
import { React, useEffect, useState } from "react";
import ConversionPagination from "./ConversionPagination";
import { isEmpty } from "lodash"

const AdflowPostbackInternal = () => {
    const [postbackData, setPostbackData] = useState({data: {}, curpage: 1, totpage: 1});
    const [rowNumber, setRowNumber] = useState('10');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await api.post('fetchpostbackinternal.php', {
                    nop: 10,
                    page: 1,
                    internal: 1
                });

                console.log(result);
                
                setPostbackData({data: result.data, curpage: result.data.curpage, totpage: result.data.totpage});
            } catch (error) {
                console.log(error)
            }
        }

        fetchData();
    }, [])

    const updatePostbackData = async (pageNumber) => {

        const n = (rowNumber === '10')?10:rowNumber;
        
        try {
            const result = await api.post('fetchpostbackinternal.php', {
                nop: n,
                page: pageNumber,
                internal: 1
            });
    
            setPostbackData({data: result.data, curpage: result.data.curpage, totpage: result.data.totpage});
        } catch (error) {
            console.log(error)
        }
    }

    const updatePostbackDataByNumber = async (e) => {

        const rowN = e.target.value;

        setRowNumber(rowN);
        
        try {
            const result = await api.post('fetchpostbackinternal.php', {
                nop: rowN,
                page: 1,
                internal: 1
            });
    
            setPostbackData({data: result.data, curpage: result.data.curpage, totpage: result.data.totpage});
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <Container>
            <Row>
                <Col>
                <Container>
                    <Row className="header-postbacks">
                        <Col md={1}>SID</Col>
                        <Col md={1}>Affiliate ID</Col>
                        <Col md={1}>Campaign ID</Col>
                        <Col md={1}>Creative ID</Col>
                        <Col md={1}>Sub 1</Col>
                        <Col md={1}>Sub 2</Col>
                        <Col md={1}>Sub 3</Col>
                        <Col md={1}>Sub 4</Col>
                        <Col md={1}>Sub 5</Col>
                        <Col md={3}>Created At</Col>
                    </Row>
                </Container>
                </Col>
            </Row>
            <Row className="header-content">
                <Col>
                { !isEmpty(postbackData.data) && postbackData.data.result.map( (data) => { return (<PostbackListItem key={data.ID} alldata={data} />) }) }
                </Col>
            </Row>
            <Row className="pagination">
                <Col>
                    <ConversionPagination curpage={postbackData.curpage} totpage={postbackData.totpage} updateData={updatePostbackData} />
                </Col>
            </Row>
            <Row className="entry-numbers">
                <Col>
                    <select id="row-numbers" onChange={updatePostbackDataByNumber}>
                        <option value="10">10</option>    
                        <option value="25">25</option>    
                        <option value="50">50</option>    
                        <option value="100">100</option> 
                    </select>
                </Col>
            </Row>
        </Container>
    );
}

export default AdflowPostbackInternal;