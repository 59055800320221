import React from "react";
import './css/ConversionPagination.scss';

const ConversionPagination = (props) => {
    const num = [...Array(props.totpage).keys()];

    return (
        <ul>
            <li className="prev" onClick={(e) => { (props.curpage!==1)?props.updateData(props.curpage - 1):e.preventDefault()}}><i className="fa-solid fa-angles-left"></i></li>
            { num.map((data) => {return <li key={data} className={(props.curpage === (data + 1) )?'pagenum active':'pagenum'} value={data + 1} onClick={() => { props.updateData(data + 1)}}>{data + 1}</li>}) }
            <li className="next" onClick={(e) => { (props.curpage!==props.totpage)?props.updateData(props.curpage + 1):e.preventDefault()}}><i className="fa-solid fa-angles-right"></i></li>
        </ul>
    );
}

export default ConversionPagination;