import {React, useState, useEffect} from "react";
import { Col, Container, Row } from "react-bootstrap";
import DatePicker from 'react-datepicker';
import Form from 'react-bootstrap/Form';
import api from "./api";
import {isEmpty} from  "lodash";
import "./css/ConversionInsert.scss"
import { Loader } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import ManualListItem from "./ManualListItem";
import ConversionPagination from "./ConversionPagination";

const ConversionInsert = () => {
    const [startDate, setStartDate] = useState(new Date());
    const [affiliateData, setAffiliateData] = useState('');
    const [campaignData, setCampaignData] = useState('');
    const [creativeData, setCreativeData] = useState('');
    const [isLoading, setIsLoading] = useState('hidden');
    const [manualData, setManualData] = useState({data: {}, curpage: 1, totpage: 1});


    useEffect(() => {
        const fetchData = async () => {
            setIsLoading('');
            try {
                const result = await api.post('affiliates.php');
                setAffiliateData(result);
                setIsLoading('hidden');

                const result2 = await api.post('fetchmanual.php', {
                    nop: 10,
                    page: 1
                });
                
                setManualData({data: result2.data, curpage: result2.data.curpage, totpage: result2.data.totpage});
            } catch (error) {
                console.log(error)
            }
        }

        fetchData();
    }, []);

    const updateCampaignData = async (e) => {
        if(isEmpty(e.target.value)) return false;

        setIsLoading('');

        try {
            const result = await api.post('campaigns.php', {
                source_affiliate_id: e.target.value
            });
    
            setCampaignData(result);
            setIsLoading('hidden');
        } catch (error) {
            console.log(error)
        }
    }

    const updateCreativeData = async (e) => {
        if(isEmpty(e.target.value)) return false;
        setIsLoading('');

        try {
            const result = await api.post('creatives.php', {
                offer_id: e.target.selectedOptions[0].getAttribute('offer_id')
            });
    
            setCreativeData(result);
            setIsLoading('hidden');
        } catch (error) {
            console.log(error)
        }
    }


    const getCookie = (n) => {
        // Split cookie string and get all individual n=value pairs in an array
        var cookieArr = document.cookie.split(";");
        
        // Loop through the array elements
        for(var i = 0; i < cookieArr.length; i++) {
            var cookiePair = cookieArr[i].split("=");
            

            if(n === cookiePair[0].trim()) {
                // Decode the cookie value and return
                return decodeURIComponent(cookiePair[1]);
            }
        }
        
        // Return null if not found
        return null;
    }


    const fireConversion = async (e) => {
        e.preventDefault();
        setIsLoading('');

        const allinput = e.target;
        
        const [c_date,aff_id,cam_id,cre_id,sb_aff,sb_id_3,payout,recevd,tranid,textnote] = allinput;

        const valid = (isEmpty(c_date.value) || isEmpty(aff_id.value) || isEmpty(cam_id.value) || isEmpty(cre_id.value) || isEmpty(payout.value) || isEmpty(recevd.value))?false:true;

        if(valid){
            try {

                localStorage.clear();
                sessionStorage.clear();

                var cookies = document.cookie.split(";");
                for (var i = 0; i < cookies.length; i++) {
                    var cookie = cookies[i];
                    var eqPos = cookie.indexOf("=");
                    var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
                    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
                }

                const sub3 = (isEmpty(sb_id_3.value))?'':'&s3='+sb_id_3.value;


                const iframe = document.createElement("iframe");
                iframe.src = "/click.html?ckmsc="+cam_id.value+"&ckmc="+cre_id.value+"&s1="+ sb_aff.value + sub3;
                //iframe.src = "https://amgtrk.com/?a="+aff_id.value+"&c="+cre_id.value+"&s1="+sb_aff.value;
                iframe.width = "1";
                iframe.height = "1";
                iframe.async = true;
                document.body.appendChild(iframe);

                let f = true;
                
                const checker = setInterval(async function(){
                    let cl = await api.post('clicks.php');
                    
                    let ipdeets = await api.get('https://www.cloudflare.com/cdn-cgi/trace');

                    
                    let ipRegex = /[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}/g;
                    let ip = ipdeets.data.match(ipRegex)[0];
                    
                    console.log('click', cl);
                    console.log('ip', ip);
                    
                    let foundClick = cl.data.find((click) => { return click.ip_address === ip && click.creative.creative_id === parseInt(cre_id.value);});

                    console.log('selected', foundClick);

                    if(!foundClick){
                        let cook = getCookie('ckmsid');
                        foundClick = cl.data.find((click) => { return click.request_session_id === cook && click.creative.creative_id === parseInt(cre_id.value);});

                        console.log('selected cookie', foundClick);
                        console.log('cook', cook);
                        console.log('creative id', parseInt(cre_id.value));
                    }

                    if(foundClick && f){
                        clearInterval(checker);
                        f = false;

                        console.log('real', foundClick);

                        const adv = await api.post('advertisers.php', {'advertiser_id': foundClick.brand_advertiser.brand_advertiser_id});
                        console.log('advertiser id',foundClick.brand_advertiser.brand_advertiser_id);
                        console.log('advertisers', adv);
            
                        const evt_id = adv.data[0].events[0].event_id;

                        const tid = (tranid.value === "")?Math.floor(Math.random() * (9999999999 - 1000000000 + 1)):tranid.value;
                        
                        const args = {
                            offer_id: foundClick.site_offer.site_offer_id,
                            evt_id: evt_id,
                            ordernum: tid,
                            sid: foundClick.request_session_id,
                            received: recevd.value,
                            payout: payout.value
                        };
            
                        cdm(args);

                        const result = await api.post('insert.php', {
                            conversion_date: c_date.value,
                            affiliate_id: aff_id.value,
                            campaign_id: cam_id.value,
                            creative_id: cre_id.value,
                            sub_affiliate: sb_aff.value,
                            payout: payout.value,
                            received: recevd.value,
                            transaction_ids: tid,
                            note: textnote.value,
                            total_to_insert: 1,
                            inserted: true
                        });
            
                        console.log('insert', result);
        
                        for(let x = 0; x < allinput.length; x++){
                            allinput[x].value = '';
                            allinput[x].classList.remove('required');
                        }
        
                        const result2 = await api.post('fetchmanual.php', {
                            nop: 10,
                            page: 1
                        });
                
                        setManualData({data: result2.data, curpage: result2.data.curpage, totpage: result2.data.totpage});

                        setIsLoading('hidden');
                        console.log('sulod');

                    }

                },3000);
                
                
            } catch (error) {
                console.log(error)
            }
        }else{
            (isEmpty(c_date.value))?c_date.className += ' required':c_date.classList.remove('required');
            (isEmpty(aff_id.value))?aff_id.className += ' required':aff_id.classList.remove('required');
            (isEmpty(cam_id.value))?cam_id.className += ' required':cam_id.classList.remove('required');
            (isEmpty(cre_id.value))?cre_id.className += ' required':cre_id.classList.remove('required');
            (isEmpty(payout.value))?payout.className += ' required':payout.classList.remove('required');
            (isEmpty(recevd.value))?recevd.className += ' required':recevd.classList.remove('required');
            
            setIsLoading('hidden');
        }
    }

    const updateManualData = async (pageNumber) => {
        
        try {
            const result = await api.post('fetchmanual.php', {
                nop: 10,
                page: pageNumber
            });
    
            setManualData({data: result.data, curpage: result.data.curpage, totpage: result.data.totpage});
        } catch (error) {
            console.log(error)
        }
    }

    const cdm = (args) => {
        const script = document.createElement("script");
        script.src = "https://amgtrk.com/p.ashx?f=js&o="+args.offer_id+"&e="+args.evt_id+"&t="+args.ordernum+"&r="+args.sid+"&p="+args.received+"&ap="+args.payout;
        script.async = true;
        document.body.appendChild(script);
    }

    return (
        <>
        <form method="POST" action="/" id="add-conversion" onSubmit={fireConversion}>
            <Loader inverse backdrop center content="loading..." className={isLoading} />
            <Container>
                <Row>
                    <Col md={3}>
                        <Row className="d-none"><Col><DatePicker name="conversion_date" className="conversion_date" placeholderText="Conversion Date" showTimeInput timeInputLabel="Time:" dateFormat="MM/dd/yyyy hh:mm aa" selected={startDate} onChange={(date) => setStartDate(date)}></DatePicker></Col></Row>
                        <Row><Col>
                            <Form.Select aria-label="Default select example" className="affiliate_id" name="affiliate_id" onChange={updateCampaignData}>
                                <option value="">Select Affiliate</option>
                                {!isEmpty(affiliateData) && affiliateData.data.map((data) => {return <option key={data.affiliate_id} value={data.affiliate_id}>{data.affiliate_name}</option>})}
                            </Form.Select>
                        </Col></Row>
                        <Row><Col>
                            <Form.Select aria-label="Default select example" className="campaign_id" name="campaign_id" onChange={updateCreativeData}>
                                <option value="">Select Campaign</option>
                                {!isEmpty(campaignData.data) && campaignData.data.map((data) => {return <option key={data.campaign_id} value={data.campaign_id} offer_id={data.site_offer.site_offer_id}>Campaign ID: {data.campaign_id} [Offer: {data.site_offer.site_offer_name}]</option>})}
                            </Form.Select>
                        </Col></Row>
                        <Row><Col>
                            <Form.Select aria-label="Default select example" className="creative_id" name="creative_id">
                                <option value="">Select Creative</option>
                                {!isEmpty(creativeData.data) && creativeData.data.map((data) => {return <option key={data.creative_id} value={data.creative_id}>{data.creative_name}</option>})}
                            </Form.Select>
                        </Col></Row>
                        <Row><Col>
                            <input type="text" name="sub_affiliate" className="sub_affiliate" placeholder="Sub Affiliate" />
                        </Col></Row>
                        <Row><Col>
                            <input type="text" name="sub_id_3" className="sub_id_3" placeholder="Sub ID 3" />
                        </Col></Row>
                        <Row><Col>
                            <input type="text" name="payout" className="payout" placeholder="Payout" />
                        </Col></Row>
                        <Row><Col>
                            <input type="text" name="received" className="received" placeholder="Received" />
                        </Col></Row>
                        <Row><Col>
                            <input type="text" name="transaction_ids" className="transaction_ids" placeholder="Transaction ID" />
                        </Col></Row>
                        <Row><Col>
                            <input type="text" name="note" className="note" placeholder="Note" />
                        </Col></Row>
                        <Row><Col><input type="submit" className="btn convert-btn" value="Add Conversion" /></Col></Row>
                    </Col>
                    <Col md={9}>
                        <Container>
                            <Row>
                                <Col>
                                <Container>
                                    <Row className="header-manual">
                                        <Col md={2}>Conversion Date</Col>
                                        <Col md={2}>Affiliate ID</Col>
                                        <Col md={2}>Campaign ID</Col>
                                        <Col md={2}>Creative ID</Col>
                                        <Col md={1}>Payout</Col>
                                        <Col md={1}>Received</Col>
                                        <Col md={2}>Transaction ID</Col>
                                    </Row>
                                </Container>
                                </Col>
                            </Row>
                            <Row className="header-content-manual">
                                <Col>
                                { !isEmpty(manualData.data) && manualData.data.result.map( (data) => { return (<ManualListItem key={data.ID} alldata={data} />) }) }
                                </Col>
                            </Row>
                            <Row className="pagination">
                                <Col>
                                    <ConversionPagination curpage={manualData.curpage} totpage={manualData.totpage} updateData={updateManualData} />
                                </Col>
                            </Row>
                        </Container>    
                    </Col>
                </Row>
            </Container>
        </form>
        </>
    );
}

export default ConversionInsert;