import React from 'react';
import MainContainer from "./components/MainContainer";

const App = () => {
  return (
    <div className="App">
      <MainContainer></MainContainer>
    </div>
  );
}

export default App;
