import React from "react";
import { Container } from "react-bootstrap";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import logo from './images/adflow-logo.png'
import './css/HeaderMain.scss'

const HeaderMain = () => {
    return (
        <Container className="header">
            <Row>
                <Col><div className="logo"><img src={logo} alt="logo" /></div></Col>
                <Col md={3}><p className="header-text">A tool to manage conversions</p></Col>
            </Row>
        </Container>
    );
}

export default HeaderMain;