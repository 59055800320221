import { React } from "react";
import { Col, Container, Row } from "react-bootstrap";

const ManualListItem = (props) => {
    
    return (
        <Container>
            <Row>
                <Col md={2} className="d-flex align-items-center">{props.alldata.conversion_date}</Col>
                <Col md={2} className="d-flex align-items-center">{props.alldata.affiliate_id}</Col>
                <Col md={2} className="d-flex align-items-center">{props.alldata.campaign_id}</Col>
                <Col md={2} className="d-flex align-items-center">{props.alldata.creative_id}</Col>
                <Col md={1} className="d-flex align-items-center">{props.alldata.payout}</Col>
                <Col md={1} className="d-flex align-items-center">{props.alldata.received}</Col>
                <Col md={2} className="d-flex align-items-center">{props.alldata.transaction_ids}</Col>
            </Row>
        </Container>
    );
}

export default ManualListItem;