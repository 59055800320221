import { Container, Row, Col} from "react-bootstrap"
import "./css/LoginForm.scss";
import api from "./api";

const LoginForm = (props) => {
    const login = async (data) => {
        
        data.preventDefault();

        console.log('form', data);

        const LOGIN_ENDPOINT = '../auth/api/login.php';
    
        try {

            const user = {email: data.target[0].value, password: data.target[1].value};
    
            let response = await api.post(LOGIN_ENDPOINT, user);
    
            if(response.status === 200 && response.data.jwt && response.data.expireAt){
                let jwt = response.data.jwt;
                let expire_at = response.data.expireAt;
    
                localStorage.setItem("access_token", jwt);
                localStorage.setItem("expire_at", expire_at);

                props.parentCallback(true);
    
            }else{
                props.parentCallback(false);
            }
    
    
        } catch(e){
            console.log(e);

            props.parentCallback(false);
        }
    }


    return (
        <Container>
            <Row>
                <Col className="login-form">
                    <form action="/" method="POST" onSubmit={login}>
                        <div className="form-group">
                            <label htmlFor="email">Email Address</label>
                            <input type="email" className="form-control" id="email" name="email" placeholder="Email" />
                        </div>
                        <div className="form-group">
                            <label htmlFor="password">Password</label>
                            <input type="password" className="form-control" id="password" placeholder="Password" />
                        </div>
                        <div className="form-group l-btn-container">
                            <input type="submit" className="btn login-btn" value="Login" />
                        </div>
                    </form>
                </Col>
            </Row>
        </Container>
    );
}

export default LoginForm;