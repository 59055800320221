import React from "react";
import { Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import ConversionInsert from "./ConversionInsert";
import ConversionListContainer from "./ConversionListContainer";
import './css/HeaderTabs.scss';
import AdflowPostback from "./AdflowPostback";
import AdflowPostbackInternal from "./AdflowPostbackInternal";

const HeaderTabs = () => {
    return (
        <Container className="main-content">
            <Row>
                <Col>
                    <Tabs defaultActiveKey="conversions" id="header-tabs">
                        <Tab eventKey="conversions" title={<span><i className="fa-solid fa-circle-check"></i> Conversions</span>}><ConversionListContainer /></Tab>
                        <Tab eventKey="add-conversions" title={<span><i className="fa-solid fa-circle-plus"></i> Add Conversions</span>}><ConversionInsert /></Tab>
                        <Tab eventKey="adflow-postbacks" title={<span><i className="fa-solid fa-asterisk"></i> Adflow Test Postback </span>}><AdflowPostback /></Tab>
                        <Tab eventKey="adflow-internal" title={<span><i className="fa-solid fa-asterisk"></i> Adflow Internal Postback </span>}><AdflowPostbackInternal /></Tab>
                    </Tabs>
                </Col>
            </Row>
        </Container>
    );
}

export default HeaderTabs;