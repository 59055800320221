import {React, useState, useEffect} from "react";
import { Container } from "react-bootstrap";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import HeaderMain from "./HeaderMain";
import HeaderTabs from "./HeaderTabs";
import LoginForm from "./LoginForm";
import api from "./api";
import {isEmpty} from  "lodash";


const MainContainer = () => {
    const [allow, setAllow] = useState(false);
    const [loginStatus, setloginStatus] = useState('');
    

    const allowCallback = (loginData) => {
        console.log('login', loginData);
        setAllow(loginData);

        if(!loginData){
            setloginStatus('Incorrect Email or Password.');
        }else{
            setloginStatus('');
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            if(!isEmpty(localStorage.getItem("expire_at"))){
                const exp = parseInt(localStorage.getItem("expire_at"));
                const tm = Math.floor(Date.now() / 1000);

                const rem = (tm - exp);

                if(rem > 3600){
                    localStorage.setItem("access_token", '');
                    localStorage.setItem("expire_at", '');
                }
            }

            const LOGIN_ENDPOINT = '../auth/api/protected.php';
            const varToken = localStorage.getItem("access_token");

            var config = {
                headers: {
                  Authorization: 'Bearer ' + varToken
                }
              };

            try {
                let response = await api.post(LOGIN_ENDPOINT, {}, config);

                if(response.data.message === "GRANTED"){
                    setAllow(true);
                }else{
                    setAllow(false);
                }
            } catch (error) {
                console.log(error)
            }
        }

        fetchData();
    }, []);

    if(allow){
        return (
            <Container>
                <Row><Col><HeaderMain /></Col></Row>
                <Row><Col><HeaderTabs /></Col></Row>
            </Container>
        );
    }else{
        return (
            <Container>
                <Row><Col><HeaderMain /></Col></Row>
                <Row><Col><LoginForm parentCallback={allowCallback} /><span className="login-status">{loginStatus}</span></Col></Row>
            </Container>
        );
    }
    
}

export default MainContainer;