import { React } from "react";
import { Col, Container, Row} from "react-bootstrap";


const ConversionListItem = (props) => {
    return (
        <Container>
            <Row>
                <Col md={1} className="d-flex align-items-center">{props.alldata.sid}</Col>
                <Col md={1} className="d-flex align-items-center">{props.alldata.affiliate_id}</Col>
                <Col md={1} className="d-flex align-items-center">{props.alldata.campaign_id}</Col>
                <Col md={1} className="d-flex align-items-center">{props.alldata.creative_id}</Col>
                <Col md={1} className="d-flex align-items-center">{props.alldata.sub1}</Col>
                <Col md={1} className="d-flex align-items-center">{props.alldata.sub2}</Col>
                <Col md={1} className="d-flex align-items-center">{props.alldata.sub3}</Col>
                <Col md={1} className="d-flex align-items-center">{props.alldata.sub4}</Col>
                <Col md={1} className="d-flex align-items-center">{props.alldata.sub5}</Col>
                <Col md={3} className="d-flex align-items-center">{props.alldata.created_at}</Col>
            </Row>
        </Container>
    );
}

export default ConversionListItem;