import React from "react";
import './css/ConversionStatus.scss';

const ConversionStatus = (props) => {
    const status = (props.sid)?'fa-solid fa-circle-check good':'fa-solid fa-circle-exclamation warning';
    const auto = (props.auto !== '0')?'fa-solid fa-arrows-rotate rotate':'';

    return (
        <>
            <div className="conversion-icon"><i className={status}></i> <span className='count'>{props.count}</span> <i className={auto}></i></div>
        </>
    );
}

export default ConversionStatus;