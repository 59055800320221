import { React, useState } from "react";
import { Col, Container, Row, Modal, Button } from "react-bootstrap";
import ConversionStatus from "./ConversionStatus";
import api from "./api";
import { Loader } from "rsuite";

const ConversionListItem = (props) => {
    const [count, setCount] = useState(props.count);
    const [isLoading, setIsLoading] = useState('hidden');
    const [modalShow, setModalShow] = useState(false);


    const fireScript = async (e) => {
        
        const d = props.alldata;
        console.log(d);

        setIsLoading('');

        try {
            const offers = await api.post('offers.php', {
              offer_id : d.offer_id
            });

            const result = await api.post('advertisers.php',{
              advertiser_id : offers.data[0].advertiser.advertiser_id
            });

            const evt_id = result.data[0].events[0].event_id;
            const ordernum = d.last_order_name.split('#');

            const args = {
                d: d,
                evt_id: evt_id,
                ordernum: ordernum[1],
                sid: d.sid
            };

            componentDidMount(args);

            updateDatabase(parseInt(count) + 1, d.ID);
            
        } catch (error) {
            console.log(error)
        }
    }

    const componentDidMount = (args) => {
        const script = document.createElement("script");
        script.src = "https://amgtrk.com/p.ashx?f=js&o="+args.d.offer_id+"&e="+args.evt_id+"&p="+args.d.subtotal_price+"&t="+args.ordernum+"&r="+args.sid;
        script.async = true;
        document.body.appendChild(script);
    }

    const updateDatabase = async (cnt,ids) => {
        try {
            const result = await api.post('fired.php', {
                count: cnt,
                ID: ids
            });
    
            console.log(result);

            setCount(parseInt(count) + 1);
            setIsLoading('hidden');

        } catch (error) {
            console.log(error)
        }
    }

    const MyVerticallyCenteredModal = (props) => {
        return (
          <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                {props.details.name}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Container className="custom-modal">
                  <Row><Col md={2}>SID: </Col><Col>{props.details.alldata.sid} </Col></Row>
                  <Row><Col md={2}>Affiliate ID: </Col><Col>{props.details.alldata.aff_id} </Col></Row>
                  <Row><Col md={2}>Browser IP: </Col><Col>{props.details.alldata.browser_ip} </Col></Row>
                  <Row><Col md={2}>Contact Email: </Col><Col>{props.details.alldata.contact_email} </Col></Row>
                  <Row><Col md={2}>Created At: </Col><Col>{props.details.alldata.created_at} </Col></Row>
                  <Row><Col md={2}>Updated At: </Col><Col>{props.details.alldata.updated_at} </Col></Row>
                  <Row><Col md={2}>Creative ID: </Col><Col>{props.details.alldata.creative_id} </Col></Row>
                  <Row><Col md={2}>Fire Count: </Col><Col>{props.details.alldata.fire_count} </Col></Row>
                  <Row><Col md={2}>First Name: </Col><Col>{props.details.alldata.first_name} </Col></Row>
                  <Row><Col md={2}>Last Name: </Col><Col>{props.details.alldata.last_name} </Col></Row>
                  <Row><Col md={2}>Shopify Order Number: </Col><Col>{props.details.alldata.last_order_name} </Col></Row>
                  <Row><Col md={2}>Offer ID: </Col><Col>{props.details.alldata.offer_id} </Col></Row>
                  <Row><Col md={2}>Order Status URL: </Col><Col>{props.details.alldata.order_status_url} </Col></Row>
                  <Row><Col md={2}>Referring Site: </Col><Col>{props.details.alldata.referring_site} </Col></Row>
                  <Row><Col md={2}>Request URL: </Col><Col>{props.details.alldata.request_url} </Col></Row>
                  <Row><Col md={2}>SUB 1: </Col><Col>{props.details.alldata.sub1} </Col></Row>
                  <Row><Col md={2}>SUB 2: </Col><Col>{props.details.alldata.sub2} </Col></Row>
                  <Row><Col md={2}>SUB 3: </Col><Col>{props.details.alldata.sub3} </Col></Row>
                  <Row><Col md={2}>SUB 4: </Col><Col>{props.details.alldata.sub4} </Col></Row>
                  <Row><Col md={2}>SUB 5: </Col><Col>{props.details.alldata.sub5} </Col></Row>
                  <Row><Col md={2}>Subtotal Price: </Col><Col>{props.details.alldata.subtotal_price} </Col></Row>
              </Container>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={props.onHide}>Close</Button>
            </Modal.Footer>
          </Modal>
        );
      }

    return (
        <Container>
            <Row>
                <Col md={1} className="d-flex align-items-center">{props.alldata.created_at}</Col>
                <Col md={1} className="d-flex align-items-center">{props.alldata.updated_at}</Col>
                <Col md={1} className="d-flex align-items-center">{props.ip}</Col>
                <Col md={1} className="d-flex align-items-center">{props.sid}</Col>
                <Col md={1} className="d-flex align-items-center">{props.alldata.aff_id}</Col>
                <Col md={2} className="d-flex align-items-center">{props.name}</Col>
                <Col md={2} className="d-flex align-items-center">{props.requrl}</Col>
                <Col md={1} className="d-flex align-items-center"><ConversionStatus sid={props.sid} count={count} auto={props.alldata.fire_auto}/></Col>
                <Col md={2} className="d-flex align-items-center"><a href="/" className="btn fire" onClick={(e) => { e.preventDefault(); }}>{(props.sid)?<div className="btn convert-btn" onClick={() => fireScript()}>Convert</div>:<></>}</a><Button className="info-btn" variant="primary" onClick={() => setModalShow(true)}><i className="fa-solid fa-circle-info"></i> Info</Button><MyVerticallyCenteredModal details={props} show={modalShow} onHide={() => setModalShow(false)} /><Loader inverse backdrop center className={isLoading} /></Col>
            </Row>
        </Container>
    );
}

export default ConversionListItem;